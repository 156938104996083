import { Typography, styled } from "@mui/material";

import { memo } from "react";

const NoDataContainer = styled("div")((t) => ({
  borderRadius: 10,
  textAlign: "center",
  padding: "36px 0",
  background: t.theme.palette.background.paper,
}));

export default memo(({ text, noBackground }) => (
  <NoDataContainer sx={noBackground ? { background: "none !important" } : ""}>
    <Typography color="primary">{text || "No data found"}</Typography>
  </NoDataContainer>
));
