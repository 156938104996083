import { useEffect } from "react";
import Cookie from "js-cookie";
import { useNavigate } from "react-router-dom";

import Loading from "../components/Loading";
import { useAuth } from "../contexts/AuthContext";
import { useSnackbar } from "../contexts/Snackbar";

export default function Azure() {
  const navigate = useNavigate();
  const { checkUser } = useAuth();
  const { showSnackbar } = useSnackbar();

  const verify = async () => {
    const token = Cookie.get("crk_access_token");
    const register = Cookie.get("crk_register");

    if (!token) {
      navigate("/");

      if (register) {
        Cookie.remove("crk_register");
        showSnackbar({
          severity: "success",
          message:
            "Successfully registered. Please wait your account approval.",
        });
      }
      return;
    }
    localStorage.setItem("access_token", token);
    Cookie.remove("crk_access_token");

    await checkUser();

    navigate("/dashboard");
  };

  useEffect(() => {
    verify();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Loading />;
}
