import { api } from "./axios";

export const verifyMe = () => api.get("/auth/me");

export const signup = (data) => api.post("/auth/register", data);

export const login = (data) => api.post("/auth/login", data);

export const emailVerify = (data) => api.post("/auth/verify", data);

export const forgotPassword = (data) => api.post("/auth/forgot-password", data);

export const resetPassword = (data) => api.post("/auth/reset-password", data);
