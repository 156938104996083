import { Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import { styled } from "@mui/system";
import CloseIcon from "@mui/icons-material/Close";

export const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 20,
    backgroundImage: "none",
  },
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  [theme.breakpoints.up("sm")]: {},
}));

export const StyledDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle
      sx={{ m: 0, px: 3, pt: 3, pb: 2, textAlign: "left" }}
      {...other}
    >
      {children}
      {onClose ? (
        <IconButton
          size="small"
          onClick={onClose}
          color="primary"
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

export const StyledDialogContent = styled(DialogContent)((t) => ({
  minWidth: 400,
  [t.theme.breakpoints.down("sm")]: {
    minWidth: 0,
    maxWidth: "100%",
  },
}));
