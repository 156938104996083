import { Grid, Pagination, PaginationItem, Typography } from "@mui/material";
import { memo } from "react";
import { Link, useLocation } from "react-router-dom";

import useQuery from "../../hooks/useQuery";
import useResponsive from "../../hooks/useResponsive";

export default memo(
  ({ pagination: { page, size = 12, total, totalPages }, pageUrl }) => {
    const { mobile } = useResponsive();
    const query = useQuery();
    const location = useLocation();

    const getItemLink = (item) => {
      const baseUrl = `${window.location.protocol}//${window.location.host}${
        pageUrl || location.pathname
      }`;

      const newSearch = new URLSearchParams(query.toString());
      newSearch.set("page", item.page);
      const q = newSearch.toString();

      return baseUrl + q ? `?${q}` : "";
    };

    return (
      <Grid
        container
        justifyContent={mobile ? "center" : "space-between"}
        alignItems="center"
        spacing={2}
      >
        <Grid item display="flex" alignItems="center">
          <Typography>Showing</Typography>
          <Typography color="primary" mx={1}>
            {(page - 1) * size + 1} -{" "}
            {page * size > total ? total : page * size}
          </Typography>
          <Typography>from</Typography>
          <Typography color="primary" mx={1}>
            {total}
          </Typography>
          <Typography>data</Typography>
        </Grid>
        <Grid item>
          <Pagination
            count={totalPages}
            variant="outlined"
            color="primary"
            siblingCount={0}
            boundaryCount={1}
            page={page}
            renderItem={(item) => (
              <PaginationItem
                component={Link}
                to={getItemLink(item)}
                {...item}
                sx={(t) => ({
                  ...item.sx,
                  fontSize: 12,
                  "&.Mui-selected": {
                    color: "#fff",
                    backgroundColor: t.palette.primary.main,
                  },
                })}
              />
            )}
          />
        </Grid>
      </Grid>
    );
  }
);
