import { api } from "./axios";

export const getUsers = (params) => api.get("/users", { params });

export const updateUser = (id, data) => api.patch(`/users/${id}`, data);

export const deleteUser = (id) => api.delete(`/users/${id}`);

export const approveUser = (id, approve) =>
  api.post(`/users/${id}/approve`, {
    approve,
  });
