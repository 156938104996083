import { createContext, useContext, useState } from "react";

const PageTitleContext = createContext({
  title: "",
  setLoading: (loading) => null,
  setPageInfo: (pageInfo) => null,
});

export const PageTitleProvider = ({ children }) => {
  const [pageInfo, setPageInfo] = useState({
    title: "",
  });
  const [loading, setLoading] = useState(false);

  return (
    <PageTitleContext.Provider
      value={{ ...pageInfo, loading, setLoading, setPageInfo }}
    >
      {children}
    </PageTitleContext.Provider>
  );
};

export const usePageTitle = () => useContext(PageTitleContext);
