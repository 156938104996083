/* eslint-disable no-use-before-define */
import { useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import useQuery from "./useQuery";

export default function useFilterQuery(filterParams) {
  const q = useQuery();
  const navigate = useNavigate();
  const location = useLocation();

  const query = useMemo(() => {
    const res = {};

    for (const param of filterParams) {
      const v = q.get(param);
      res[param] = v === undefined || v === null ? "" : v;
    }
    return res;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [q]);

  const updateFilter = (name, value) => {
    const searchQuery = new URLSearchParams(q.toString());

    Object.keys(query).forEach((key) => {
      if (query[key]) {
        searchQuery.set(key, query[key]);
      }
    });

    if (value) {
      searchQuery.set(name, value);
    } else {
      searchQuery.delete(name);
    }
    searchQuery.delete("page");

    navigate({
      pathname: location.pathname,
      search: `?${searchQuery.toString()}`,
    });
  };

  return { query, updateFilter, q };
}
