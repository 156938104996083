import {
  Button,
  DialogActions,
  Grid,
  IconButton,
  Typography,
  styled,
} from "@mui/material";
import { useCallback, useState } from "react";
import CancelIcon from "@mui/icons-material/Cancel";
import { useDropzone } from "react-dropzone";

import {
  StyledDialog,
  StyledDialogContent,
  StyledDialogTitle,
} from "../shared/DialogElements";
import { humanFileSize } from "../../helper/utils";

const DropZone = styled("div")(() => ({
  border: "2px dashed #56566a",
  borderRadius: 16,
  padding: "120px 1.25rem",
  textAlign: "center",
  background: "#27273a",
  position: "relative",
}));

export default function UploadDocModal({ onClose, onSubmit }) {
  const [file, setFile] = useState();

  const onDrop = useCallback((acceptedFiles) => {
    const [file] = acceptedFiles;
    setFile(file);
  }, []);

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop,
    accept: {
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        [],
      "application/msword": [],
    },
    useFsAccessApi: false,
    multiple: false,
  });

  return (
    <StyledDialog fullWidth open onClose={onClose} disableEscapeKeyDown>
      <StyledDialogTitle onClose={onClose}>Upload</StyledDialogTitle>
      <StyledDialogContent dividers>
        {file ? (
          <Grid
            container
            justifyContent="space-between"
            spacing={1}
            py={3}
            px={2}
            wrap="nowrap"
          >
            <Grid item xs={8}>
              <Typography
                fontSize={14}
                overflow="hidden"
                color="primary"
                noWrap
                textOverflow="ellipsis"
                maxWidth={"100%"}
              >
                {file.name}
              </Typography>
            </Grid>
            <Grid item>
              <Typography noWrap>{humanFileSize(file.size)}</Typography>
            </Grid>
            <IconButton onClick={() => setFile(null)}>
              <CancelIcon />
            </IconButton>
          </Grid>
        ) : (
          <DropZone {...getRootProps()} onClick={(e) => e.stopPropagation()}>
            <input {...getInputProps()} />
            {isDragActive ? (
              <p>Drop the images here ...</p>
            ) : (
              <div>
                <Typography color="primary" mb={3}>
                  Accepting .docx file only
                </Typography>
                <Button variant="contained" onClick={open}>
                  Browse File
                </Button>
              </div>
            )}
          </DropZone>
        )}
      </StyledDialogContent>
      <DialogActions>
        <Button
          disabled={!file}
          color="orange"
          variant="contained"
          sx={{ mr: 1, my: 1, width: 120 }}
          onClick={() => onSubmit(file)}
        >
          Submit
        </Button>
      </DialogActions>
    </StyledDialog>
  );
}
