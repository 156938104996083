import {
  Navigate,
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";

import "./App.css";
import SignIn from "./pages/Signin";
import SignUp from "./pages/Signup";
import Layout from "./layout/Layout";
import { useAuth } from "./contexts/AuthContext";
import AuthLayout from "./layout/AuthLayout";
import Documents from "./pages/Documents";
import Users from "./pages/Admin/Users";
import Activities from "./pages/Admin/Activities";
import Profile from "./pages/Profile";
import ForgotPassword from "./pages/ForgotPassword";
import Document from "./pages/Document";
import Azure from "./pages/Azure";
import Models from "./pages/Admin/Models";

function RequireAuth({ children }) {
  const { user, loading } = useAuth();

  return loading ? <></> : user ? children : <Navigate to="/" replace />;
}

function RequireNoneAuth({ children }) {
  const { user, loading } = useAuth();

  return loading ? (
    <></>
  ) : user ? (
    <Navigate to="/dashboard" replace />
  ) : (
    children
  );
}

function RequireAdmin({ children }) {
  const { user, loading } = useAuth();

  return loading ? <></> : user?.admin ? children : <Navigate to="/" replace />;
}

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" exact element={<Layout />}>
      <Route
        path="/"
        exact
        element={
          <RequireNoneAuth>
            <SignIn />
          </RequireNoneAuth>
        }
      />
      <Route
        path="/azure/redirect"
        exact
        element={
          <RequireNoneAuth>
            <Azure />
          </RequireNoneAuth>
        }
      />
      <Route
        path="/signup"
        exact
        element={
          <RequireNoneAuth>
            <SignUp />
          </RequireNoneAuth>
        }
      />
      <Route
        path="/reset-password"
        exact
        element={
          <RequireNoneAuth>
            <ForgotPassword />
          </RequireNoneAuth>
        }
      />
      <Route
        path="/dashboard"
        exact
        element={
          <RequireAuth>
            <AuthLayout />
          </RequireAuth>
        }
      >
        <Route path="documents" exact element={<Documents />} />
        <Route path="documents/:id" exact element={<Document />} />
        <Route path="profile" exact element={<Profile />} />
        <Route path="" exactly element={<Navigate to="documents" />} />
      </Route>
      <Route
        path="/admin"
        exact
        element={
          <RequireAdmin>
            <AuthLayout />
          </RequireAdmin>
        }
      >
        <Route path="users" exact element={<Users />} />
        <Route path="activities" exact element={<Activities />} />
        <Route path="models" exact element={<Models />} />
      </Route>
    </Route>
  )
);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
