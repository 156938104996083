import { api } from "./axios";

export const createDocument = (formData) =>
  api.postForm("/documents", formData);

export const getDocuments = (params) => api.get("/documents", { params });

export const getDocument = (id) => api.get(`/documents/${id}`);

export const downloadDocument = (id, modelId, type) =>
  api.get(`/documents/${id}/download`, {
    params: {
      modelId,
      type,
    },
    responseType: "blob",
  });

export const analyzeDocument = (id, modelId) =>
  api.post(`/documents/${id}/analyze`, {
    modelId,
  });

export const deleteDocument = (id) => api.delete(`/documents/${id}`);
