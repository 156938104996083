import { createContext, useContext, useEffect, useState } from "react";
import { verifyMe, login as loginApi, emailVerify } from "../apis/auth";

export const AuthContext = createContext({
  loading: false,
  user: null,
  login: async () => null,
  logout: () => null,
  checkUser: async () => null,
});

export const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState({
    user: null,
    loading: true,
  });

  const checkUser = async () => {
    if (!localStorage.getItem("access_token")) {
      setAuth({
        user: null,
        loading: false,
      });
      return;
    }

    try {
      const { data: user } = await verifyMe();
      setAuth({
        user,
        loading: false,
      });
    } catch (err) {
      console.error(err);
      setAuth({
        user: null,
        loading: false,
      });
    }
  };

  const login = async (logindata) => {
    try {
      const { data } = logindata.code
        ? await emailVerify(logindata)
        : await loginApi(logindata);

      if (data.requireVerification) {
        return data;
      }
      const { access_token, user } = data;

      setAuth({
        user,
        loading: false,
      });
      localStorage.setItem("access_token", access_token);
    } catch (err) {
      console.error(err);
      setAuth({
        user: null,
        loading: false,
      });
      throw err;
    }
  };

  const logout = () => {
    localStorage.removeItem("access_token");
    setAuth({
      user: null,
      loading: false,
    });
  };

  useEffect(() => {
    checkUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AuthContext.Provider
      value={{
        ...auth,
        login,
        logout,
        checkUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
