import { useEffect, useState } from "react";
import {
  Card,
  Chip,
  Grid,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import moment from "moment";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";

import useLoading from "../../hooks/useLoading";
import {
  updateUser,
  deleteUser,
  getUsers,
  approveUser,
} from "../../apis/users";
import useQuery from "../../hooks/useQuery";
import LoadableSection from "../../components/shared/LoadableSection";
import { useSnackbar } from "../../contexts/Snackbar";
import { useConfirm } from "../../contexts/Confirm";
import Pagination from "../../components/shared/Pagination";

export default function Users() {
  const [data, setData] = useState();
  const { loading, sendRequest } = useLoading();
  const q = useQuery();
  const { showSnackbar } = useSnackbar();
  const confirm = useConfirm();
  const [selectedUser, setSelectedUser] = useState();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event, item) => {
    setAnchorEl(event.currentTarget);
    setSelectedUser(item);
  };
  const handleClose = () => {
    setSelectedUser(null);
    setAnchorEl(null);
  };

  const fetchUsers = async () => {
    const { data } = await sendRequest(
      () =>
        getUsers({
          page: q.get("page") || 1,
        }),
      "Failed to load users"
    );
    setData(data);
  };

  const handleUpdateUser = async () => {
    handleClose();

    try {
      await updateUser(selectedUser.id, {
        admin: !selectedUser.admin,
      });
      fetchUsers();
    } catch (err) {
      showSnackbar({
        severity: "error",
        message: "Failed to update the user",
      });
    }
  };

  const handleApprove = async () => {
    handleClose();

    try {
      await approveUser(selectedUser.id, !selectedUser.approved);
      fetchUsers();
    } catch (err) {
      showSnackbar({
        severity: "error",
        message: "Something went wrong",
      });
    }
  };

  const handleDelete = async () => {
    handleClose();

    if (
      !(await confirm({
        title: "Delete the user?",
        text: "The user will be deleted permanently.",
      }))
    ) {
      return;
    }

    try {
      await deleteUser(selectedUser.id);
      showSnackbar({
        severity: "success",
        message: "The user has been deleted.",
      });
      fetchUsers();
    } catch (err) {
      console.error(err);
      showSnackbar({
        severity: "error",
        message: "Failed to delete the user",
      });
    }
  };

  useEffect(() => {
    fetchUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [q]);

  return (
    <div>
      <Grid container alignItems="center" justifyContent="space-between" mb={3}>
        <Typography variant="h6">Users</Typography>
      </Grid>

      <LoadableSection loading={loading}>
        {data?.items?.length > 0 && (
          <>
            <TableContainer sx={{ mb: 3 }} component={Card}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ py: 2 }}>User Name</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>Approved</TableCell>
                    <TableCell>Admin</TableCell>
                    <TableCell>Created Date</TableCell>
                    <TableCell align="right"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.items.map((item) => (
                    <TableRow
                      key={item.id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell>
                        <Typography
                          fontSize={14}
                          overflow="hidden"
                          color="primary"
                          noWrap
                          textOverflow="ellipsis"
                          maxWidth={300}
                        >
                          {item.firstName + " " + item.lastName}
                        </Typography>
                      </TableCell>

                      <TableCell>
                        <Typography>{item.email}</Typography>
                      </TableCell>
                      <TableCell>
                        <Chip
                          label={item.approved ? "Yes" : "No"}
                          color={item.approved ? "success" : "error"}
                          variant="outlined"
                        />
                      </TableCell>
                      <TableCell>
                        {item.admin && <Chip label="Admin" color="secondary" />}
                      </TableCell>
                      <TableCell>
                        {moment(item.createdAt).format("YYYY-MM-DD hh:mm")}
                      </TableCell>
                      <TableCell align="right">
                        <IconButton onClick={(e) => handleClick(e, item)}>
                          <MoreVertIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Pagination pagination={data.pagination} />
            <Menu
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              <MenuItem onClick={() => handleApprove()}>
                <ListItemIcon>
                  <PermIdentityIcon />
                </ListItemIcon>

                <ListItemText>
                  <Typography>
                    {selectedUser?.approved ? "Disable" : "Approve"}
                  </Typography>
                </ListItemText>
              </MenuItem>
              <MenuItem onClick={() => handleUpdateUser()}>
                <ListItemIcon>
                  <VerifiedUserIcon />
                </ListItemIcon>
                <ListItemText>
                  <Typography>
                    {selectedUser?.admin ? "Unset" : "Set"} Admin
                  </Typography>
                </ListItemText>
              </MenuItem>
              <MenuItem onClick={() => handleDelete()}>
                <ListItemIcon>
                  <DeleteIcon color="error" />
                </ListItemIcon>
                <ListItemText>
                  <Typography color="error">Delete User</Typography>
                </ListItemText>
              </MenuItem>
            </Menu>
          </>
        )}
      </LoadableSection>
    </div>
  );
}
