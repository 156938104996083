import { useEffect, useState } from "react";
import {
  Card,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import moment from "moment";

import useLoading from "../../hooks/useLoading";
import { getActivities } from "../../apis/activities";
import LoadableSection from "../../components/shared/LoadableSection";
import Pagination from "../../components/shared/Pagination";
import { ACTIVITY_MAPPING } from "../../helper/consts";
import { DatePicker } from "@mui/x-date-pickers";
import useFilterQuery from "../../hooks/useFilterQuery";

export default function Activities() {
  const [data, setData] = useState();
  const { loading, sendRequest } = useLoading();
  const { query, updateFilter } = useFilterQuery([
    "page",
    "start",
    "end",
    "search",
  ]);

  const fetchActivities = async () => {
    const { data } = await sendRequest(
      () =>
        getActivities({
          ...query,
          page: query.page || 1,
        }),
      "Failed to load activities"
    );
    setData(data);
  };

  useEffect(() => {
    fetchActivities();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  return (
    <div>
      <Grid container alignItems="center" justifyContent="space-between" mb={3}>
        <Typography variant="h6">Activities</Typography>
      </Grid>

      <Grid container spacing={2} mb={2}>
        <Grid item xs={12} sm={6} md={3} lg={2}>
          <DatePicker
            label="Start Date"
            onChange={(e) => updateFilter("start", e)}
            value={query.start ? moment(query.start) : null}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={2}>
          <DatePicker
            label="End Date"
            onChange={(e) => updateFilter("end", e)}
            value={query.end ? moment(query.end) : null}
          />
        </Grid>
      </Grid>

      <LoadableSection loading={loading}>
        {data?.items?.length > 0 && (
          <>
            <TableContainer sx={{ mb: 3 }} component={Card}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ py: 2 }}>User</TableCell>
                    <TableCell>Activity</TableCell>
                    <TableCell>Doc</TableCell>
                    <TableCell>Date</TableCell>
                    <TableCell align="right"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.items.map((item) => (
                    <TableRow
                      key={item.id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell>
                        <Typography
                          fontSize={14}
                          overflow="hidden"
                          color="primary"
                          noWrap
                          textOverflow="ellipsis"
                          maxWidth={300}
                        >
                          {item.user.firstName + " " + item.user.lastName}
                        </Typography>
                      </TableCell>

                      <TableCell>
                        <Typography fontSize={14} color="secondary">
                          {ACTIVITY_MAPPING[item.name]}
                        </Typography>
                      </TableCell>

                      <TableCell>{item.meta?.entityName}</TableCell>
                      <TableCell>
                        {moment(item.createdAt).format("YYYY-MM-DD hh:mm")}
                      </TableCell>
                      <TableCell align="right"></TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Pagination pagination={data.pagination} />
          </>
        )}
      </LoadableSection>
    </div>
  );
}
