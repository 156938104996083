import { Button, Grid, Typography } from "@mui/material";
import { memo } from "react";
import {
  StyledDialog,
  StyledDialogContent,
  StyledDialogTitle,
} from "./shared/DialogElements";

export default memo(
  ({
    options: { title, text, okText, cancelText },
    onClose,
    open,
    onCancel,
    onConfirm,
  }) => {
    return (
      <StyledDialog onClose={onClose} open={open}>
        <StyledDialogTitle onClose={onClose}>
          <Typography color="primary" fontWeight={500} textAlign="center">
            {title}
          </Typography>
        </StyledDialogTitle>
        {text && (
          <StyledDialogContent>
            <Typography textAlign="center" fontSize={14}>
              {text}
            </Typography>
          </StyledDialogContent>
        )}
        <Grid container justifyContent="center" py={2} spacing={2}>
          <Grid item>
            <Button
              sx={{
                minWidth: 140,
              }}
              autoFocus
              onClick={onConfirm}
              color="warning"
              variant="contained"
            >
              {okText}
            </Button>
          </Grid>
          <Grid item>
            {cancelText && (
              <Button
                color="primary"
                onClick={onCancel}
                variant="outlined"
                sx={{
                  minWidth: 140,
                }}
              >
                {cancelText}
              </Button>
            )}
          </Grid>
        </Grid>
      </StyledDialog>
    );
  }
);
