import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { useNavigate } from "react-router-dom";

import RouterLink from "../components/shared/RouterLink";
import { useSnackbar } from "../contexts/Snackbar";
import { useAuth } from "../contexts/AuthContext";
import { validateEmail } from "../helper/utils";
import MicrosoftLogin from "../components/MicrosoftLogin";

export default function SignIn() {
  const [loading, setLoading] = React.useState(false);
  const navigate = useNavigate();
  const { showSnackbar } = useSnackbar();
  const { login } = useAuth();
  const [verification, setVerification] = React.useState(false);
  const [credentials, setCredentials] = React.useState({
    email: "",
    password: "",
    code: "",
  });

  const handleUpdate = (e) => {
    setCredentials((v) => ({ ...v, [e.target.name]: e.target.value }));
  };

  const handleSubmit = async () => {
    const logindata = {
      email: credentials.email,
      password: credentials.password,
    };

    if (!logindata.email || !logindata.password) {
      showSnackbar({
        severity: "error",
        message: "Please input credentials",
      });
      return false;
    }

    if (!validateEmail(logindata.email)) {
      return showSnackbar({
        severity: "error",
        message: "Invalid email address",
      });
    }

    if (verification && !credentials.code) {
      return showSnackbar({
        severity: "error",
        message: "Please input the verification code",
      });
    }
    setLoading(true);

    try {
      const res = await login(verification ? credentials : logindata);

      if (res?.requireVerification) {
        showSnackbar({
          severity: "info",
          message: "Login with a verification code sent to your email",
        });
        setVerification(true);
        setLoading(false);
        return false;
      }
      navigate("/dashboard");
    } catch (err) {
      showSnackbar({
        severity: "error",
        message: err?.response?.data?.message || "Invalid credentials",
      });
    }
    setLoading(false);
    return false;
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <Box sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            disabled={loading || verification}
            value={credentials.email}
            onChange={handleUpdate}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            disabled={loading || verification}
            value={credentials.password}
            onChange={handleUpdate}
          />
          {verification && (
            <TextField
              required
              fullWidth
              name="code"
              label="Code"
              disabled={loading}
              helperText="Input the code from your email"
              margin="normal"
              value={credentials.code}
              onChange={handleUpdate}
              // InputProps={{
              //   endAdornment: <Button size="small">Resend</Button>,
              // }}
            />
          )}
          <Button
            fullWidth
            color="orange"
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            disabled={loading}
            onClick={handleSubmit}
          >
            {verification ? "Verify" : "Sign In"}
          </Button>
          <Grid container>
            <Grid item xs>
              <RouterLink to="/reset-password">
                <Link component="div" variant="body2">
                  Forgot password?
                </Link>
              </RouterLink>
            </Grid>
            <Grid item>
              <RouterLink to="/signup">
                <Link component="div" variant="body2">
                  Don't have an account? Sign Up
                </Link>
              </RouterLink>
            </Grid>
          </Grid>
          <MicrosoftLogin loading={loading} />
        </Box>
      </Box>
    </Container>
  );
}
