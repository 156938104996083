import { styled } from "@mui/material/styles";
import { memo } from "react";
import LoadingSpinner from "./LoadingSpinner";

const Wrapper = styled("div")((t) => ({
  position: "fixed",
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  background: "rgba(0,0,0,0.5)",
  zIndex: 9999,
}));

const Loading = memo(() => {
  return <LoadingSpinner />;
});

export default memo(() => {
  return (
    <Wrapper>
      <Loading />
    </Wrapper>
  );
});
