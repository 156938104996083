import { useMediaQuery, useTheme } from "@mui/material";

export default function useResponsive() {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("md"));
  const downSm = useMediaQuery(theme.breakpoints.down("sm"));
  const downLg = useMediaQuery(theme.breakpoints.down("lg"));
  const downXs = useMediaQuery(theme.breakpoints.down("xs"));

  return { mobile, downMd: mobile, theme, downSm, downLg, downXs };
}
