import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import LockOpenIcon from "@mui/icons-material/LockOpen";

import RouterLink from "../components/shared/RouterLink";
import { signup } from "../apis/auth";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "../contexts/Snackbar";
import { validateEmail } from "../helper/utils";
import MicrosoftLogin from "../components/MicrosoftLogin";

export default function SignUp() {
  const [loading, setLoading] = React.useState(false);
  const navigate = useNavigate();
  const { showSnackbar } = useSnackbar();

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const data = new FormData(event.currentTarget);
      const user = {
        firstName: data.get("firstName"),
        lastName: data.get("lastName"),
        email: data.get("email"),
        password: data.get("password"),
      };
      if (!user.firstName || !user.lastName || !user.email || !user.password) {
        return showSnackbar({
          severity: "error",
          message: "Please fill all the fields",
        });
      }

      if (!validateEmail(user.email)) {
        return showSnackbar({
          severity: "error",
          message: "Invalid email",
        });
      }

      if (
        !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(
          user.password
        )
      ) {
        return showSnackbar({
          severity: "error",
          message: "Password is not strong enough",
        });
      }

      if (user.password !== data.get("password_confirmation")) {
        return showSnackbar({
          severity: "error",
          message: "Password not matching",
        });
      }
      setLoading(true);

      await signup(user);
      showSnackbar({
        severity: "success",
        message: "Successfully registered",
      });
      navigate("/");
    } catch (err) {
      console.error(err);
      showSnackbar({
        severity: "error",
        message: err?.response?.data?.message || "Registration has been failed",
      });
    }
    setLoading(false);
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
          <LockOpenIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign up
        </Typography>
        <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                name="firstName"
                required
                fullWidth
                id="firstName"
                label="First Name"
                autoFocus
                disabled={loading}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                fullWidth
                id="lastName"
                label="Last Name"
                name="lastName"
                disabled={loading}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                type="email"
                disabled={loading}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                disabled={loading}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                name="password_confirmation"
                label="Password Confirmation"
                type="password"
                id="password-confirmation"
                disabled={loading}
                helperText={
                  <ul style={{ paddingLeft: 8 }}>
                    <li>At least 8 characters long</li>
                    <li> Contains at least one uppercase letter</li>
                    <li> Contains at least one lowercase letter</li>
                    <li>Contains at least one number</li>
                    <li>
                      Contains at least one special character (e.g. !, @, #, $,
                      %, ^, &, *)
                    </li>
                  </ul>
                }
              />
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            color="orange"
            variant="contained"
            sx={{ mt: 3 }}
            disabled={loading}
          >
            Sign Up
          </Button>
          <MicrosoftLogin loading={loading} />
          <Grid container justifyContent="flex-end">
            <Grid item>
              <RouterLink to="/">
                <Link component="div" variant="body2">
                  Already have an account? Sign in
                </Link>
              </RouterLink>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  );
}
