import { api } from "./axios";

export const getModels = () => api.get("/ai-models");

export const setModel = (openAiModelId, enable) =>
  api.post(`/ai-models`, {
    openAiModelId,
    enable,
  });

export const getEnabledModels = () => api.get("/ai-models/enabled");
