import axios from "axios";

export const api = axios.create({
  baseURL: process.env.REACT_APP_SERVER_URL,
  headers: {
    "content-type": "application/json",
  },
});

api.interceptors.request.use((config) => {
  const token = localStorage.getItem("access_token");
  config.headers.authorization = token ? `Bearer ${token}` : "";
  return config;
});
