export const ACTIVITY_MAPPING = [
  "Sign Up",
  "Upload Doc",
  "Delete Doc",
  "Reset Password",
  "Analyze Doc",
  "Download Doc",
];

export const STATUS_COLOR_MAPPING = {
  uploaded: "info",
  ready: "success",
  processing: "warning",
  failed: "error",
};
