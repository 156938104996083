import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { useNavigate } from "react-router-dom";

import RouterLink from "../components/shared/RouterLink";
import { useSnackbar } from "../contexts/Snackbar";
import { validateEmail } from "../helper/utils";
import { forgotPassword, resetPassword } from "../apis/auth";

export default function ForgotPassword() {
  const [loading, setLoading] = React.useState(false);
  const navigate = useNavigate();
  const { showSnackbar } = useSnackbar();
  const [data, setData] = React.useState({
    email: "",
    code: "",
    password: "",
    passwordConfirmation: "",
  });

  const [verificationSubmitted, setVerificationSubmitted] =
    React.useState(false);

  const handleUpdate = (e) => {
    setData((v) => ({ ...v, [e.target.name]: e.target.value }));
  };

  const handleSubmit = async (resend) => {
    if (!verificationSubmitted || resend) {
      if (!validateEmail(data.email)) {
        return showSnackbar({
          severity: "error",
          message: "Invalid email",
        });
      }

      try {
        setLoading(true);
        await forgotPassword({ email: data.email });
        setVerificationSubmitted(true);
        setLoading(false);

        return showSnackbar({
          severity: "success",
          message: "Please check your email for the verification code",
        });
      } catch (err) {
        setLoading(false);
        console.error(err);
        return showSnackbar({
          severity: "error",
          message:
            err?.response?.data?.message ||
            "Something went wrong, please try again later",
        });
      }
    } else {
      if (
        !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(
          data.password
        )
      ) {
        return showSnackbar({
          severity: "error",
          message: "Password is not strong enough",
        });
      }

      if (data.password !== data.passwordConfirmation) {
        return showSnackbar({
          severity: "error",
          message: "Password not matching",
        });
      }
      setLoading(true);

      try {
        setLoading(true);
        await resetPassword(data);

        navigate("/");

        showSnackbar({
          severity: "success",
          message: "Your password has been reset",
        });
      } catch (err) {
        console.error(err);

        showSnackbar({
          severity: "error",
          message:
            err?.response?.data?.message ||
            "Something went wrong, please try again later",
        });
      }
      setLoading(false);
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
          <VpnKeyIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Reset Password
        </Typography>
        <Box sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            label="Email Address"
            name="email"
            type="email"
            autoFocus
            disabled={loading || verificationSubmitted}
            value={data.email}
            onChange={handleUpdate}
          />
          {verificationSubmitted && (
            <>
              <TextField
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                disabled={loading}
                value={data.password}
                onChange={handleUpdate}
                margin="normal"
              />
              <TextField
                required
                fullWidth
                name="passwordConfirmation"
                label="Password Confirmation"
                type="password"
                disabled={loading}
                value={data.passwordConfirmation}
                onChange={handleUpdate}
                helperText={
                  <ul style={{ paddingLeft: 8 }}>
                    <li>At least 8 characters long</li>
                    <li> Contains at least one uppercase letter</li>
                    <li> Contains at least one lowercase letter</li>
                    <li>Contains at least one number</li>
                    <li>
                      Contains at least one special character (e.g. !, @, #, $,
                      %, ^, &, *)
                    </li>
                  </ul>
                }
                margin="normal"
              />
              <TextField
                required
                fullWidth
                name="code"
                label="Code"
                type="number"
                disabled={loading}
                value={data.code}
                onChange={handleUpdate}
                helperText="Input the code from your email"
                margin="normal"
              />
            </>
          )}
          <Button
            fullWidth
            color="orange"
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            disabled={loading}
            onClick={() => handleSubmit()}
          >
            Submit
          </Button>
          <Grid container justifyContent="space-between">
            <Grid item>
              <Link
                component="div"
                variant="body2"
                onClick={() => handleSubmit(true)}
                sx={{ cursor: "pointer" }}
              >
                Resend verification code
              </Link>
            </Grid>
            <Grid item>
              <RouterLink to="/">
                <Link component="div" variant="body2">
                  Sign in
                </Link>
              </RouterLink>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  );
}
