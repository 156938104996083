import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import PeopleAltRoundedIcon from "@mui/icons-material/PeopleAltRounded";
import EventNoteRoundedIcon from "@mui/icons-material/EventNoteRounded";
import ModelTrainingIcon from '@mui/icons-material/ModelTraining';

export const SIDE_NAV_MENU_LIST = {
  dashboard: [
    {
      title: "Documents",
      link: "/dashboard/documents",
      icon: <TextSnippetIcon />,
    },
  ],
  admin: [
    {
      title: "Users",
      link: "/admin/users",
      icon: <PeopleAltRoundedIcon />,
      admin: true,
    },
    {
      title: "Activities",
      link: "/admin/activities",
      icon: <EventNoteRoundedIcon />,
      admin: true,
    },
    {
      title: "Models",
      link: "/admin/models",
      icon: <ModelTrainingIcon />,
      admin: true,
    },
  ],
};
