import { Button, Chip, Grid, Typography } from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";

export default function DocumentOutput({ output, handleDownloadDocument }) {
  return (
    <div>
      <Grid container justifyContent="space-between" alignItems="center" mb={2}>
        <Chip label="Clean" variant="outlined" sx={{ width: 80 }} />
        <Typography
          color="primary"
          sx={{
            maxWidth: 400,
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
            ml: 2,
            mr: "auto",
          }}
        >
          {output.cleanFileName}
        </Typography>
        <Button
          color="orange"
          variant="contained"
          sx={{ ml: 2 }}
          onClick={() => handleDownloadDocument(output, "clean")}
        >
          <DownloadIcon />
        </Button>
      </Grid>
      <Grid container justifyContent="space-between" alignItems="center" mb={2}>
        <Chip label="Compare" variant="outlined" sx={{ width: 80 }} />
        <Typography
          color="primary"
          sx={{
            maxWidth: 400,
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
            ml: 2,
            mr: "auto",
          }}
        >
          {output.diffFileName}
        </Typography>
        <Button
          color="orange"
          variant="contained"
          sx={{ ml: 2 }}
          onClick={() => handleDownloadDocument(output, "diff")}
        >
          <DownloadIcon />
        </Button>
      </Grid>
    </div>
  );
}
