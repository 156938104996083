import { useEffect, useState } from "react";
import { Button, Grid, TextField, Typography } from "@mui/material";

import { useAuth } from "../contexts/AuthContext";
import { useSnackbar } from "../contexts/Snackbar";
import { updateUser } from "../apis/users";
import { usePageTitle } from "../contexts/PageTitleContext";

export default function Profile() {
  const { user, checkUser } = useAuth();
  const { showSnackbar } = useSnackbar();
  const [profile, setProfile] = useState({});
  const [loading, setLoading] = useState(false);

  const { setPageInfo } = usePageTitle();

  const onChangeProfile = (e) => {
    setProfile((v) => ({ ...v, [e.target.name]: e.target.value }));
  };

  const handleSubmit = async () => {
    const { firstName, lastName } = profile;

    if (!firstName || !lastName) {
      return showSnackbar({
        severity: "error",
        message: "Please fill all the required fields",
      });
    }
    setLoading(true);

    try {
      await updateUser(user.id, { firstName, lastName });
      await checkUser();
      showSnackbar({
        severity: "success",
        message: "Profile has been updated",
      });
    } catch (err) {
      console.error(err);
      showSnackbar({
        severity: "error",
        message: "Failed to update",
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    setProfile(user);
  }, [user]);

  useEffect(() => {
    setPageInfo({ title: "Profile" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid container>
      <Grid item xs={12} md={9} lg={8}>
        <Typography variant="h6" mb={3}>
          Profile Settings
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <TextField
              required
              name="firstName"
              fullWidth
              label="First Name"
              value={profile.firstName || ""}
              onChange={onChangeProfile}
              error={!profile.firstName}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              required
              name="lastName"
              fullWidth
              label="Last Name"
              value={profile.lastName || ""}
              onChange={onChangeProfile}
              error={!profile.lastName}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              required
              name="email"
              fullWidth
              label="Email"
              type="email"
              disabled
              value={profile.email || ""}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              color="orange"
              variant="contained"
              onClick={handleSubmit}
              disabled={loading}
            >
              Save Changes
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
