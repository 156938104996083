import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Outlet, useLocation } from "react-router-dom";
import { Avatar, IconButton, Menu, MenuItem, Tooltip } from "@mui/material";
import { deepOrange } from "@mui/material/colors";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import LogoutIcon from "@mui/icons-material/Logout";

import { useAuth } from "../contexts/AuthContext";
import Link from "../components/shared/Link";
import { SIDE_NAV_MENU_LIST } from "./side-navitems";
import { usePageTitle } from "../contexts/PageTitleContext";

const drawerWidth = 240;

export default function AuthLayout() {
  const { user, logout } = useAuth();
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const location = useLocation();

  const { title } = usePageTitle();

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const selectedNavItem = React.useMemo(() => {
    return [...SIDE_NAV_MENU_LIST.dashboard, ...SIDE_NAV_MENU_LIST.admin].find(
      (t) => t.link === location.pathname.split("?").shift()
    );
  }, [location.pathname]);

  return (
    <Box sx={{ display: "flex" }}>
      <AppBar
        position="fixed"
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
        elevation={1}
      >
        <Toolbar>
          <Typography variant="h6" noWrap component="div">
            {selectedNavItem?.title || title}
          </Typography>
          <Box sx={{ flexGrow: 1 }} />

          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title={`${user.firstName} ${user.lastName}`}>
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar
                  variant="rounded"
                  src=""
                  sx={{ bgcolor: deepOrange[300], color: "white" }}
                >
                  {`${user.firstName[0]}${user.lastName[0]}`}
                </Avatar>
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              PaperProps={{
                sx: {
                  minWidth: 160,
                },
              }}
              onClose={handleCloseUserMenu}
            >
              <Link to="/dashboard/profile">
                <MenuItem
                  onClick={() => {
                    handleCloseUserMenu();
                  }}
                >
                  <ListItemIcon>
                    <PersonOutlineIcon />
                  </ListItemIcon>
                  <ListItemText>Profile</ListItemText>
                </MenuItem>
              </Link>
              <MenuItem
                onClick={() => {
                  handleCloseUserMenu();
                  logout();
                }}
              >
                <ListItemIcon>
                  <LogoutIcon />
                </ListItemIcon>
                <ListItemText>Log out</ListItemText>
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
      >
        <Toolbar />
        <Box sx={{ overflow: "auto" }}>
          <List>
            {SIDE_NAV_MENU_LIST.dashboard.map((item) => (
              <Link key={item.title} to={item.link}>
                <ListItem
                  disablePadding
                  sx={() => ({
                    display: "block",
                    background:
                      selectedNavItem?.link === item.link
                        ? "linear-gradient(45deg, #FE6B8B55 30%, #FF8E5355 90%)"
                        : "transparent",
                  })}
                >
                  <ListItemButton>
                    <ListItemIcon>{item.icon}</ListItemIcon>
                    <ListItemText primary={item.title} />
                  </ListItemButton>
                </ListItem>
              </Link>
            ))}
          </List>
          {user.admin && (
            <List
              subheader={
                <Typography
                  px={2}
                  py={1}
                  fontWeight={600}
                  fontSize={16}
                  color="primary"
                >
                  Admin
                </Typography>
              }
            >
              {SIDE_NAV_MENU_LIST.admin.map((item) => (
                <Link key={item.title} to={item.link}>
                  <ListItem
                    disablePadding
                    sx={() => ({
                      display: "block",
                      background:
                        selectedNavItem?.link === item.link
                          ? "linear-gradient(45deg, #FE6B8B55 30%, #FF8E5355 90%)"
                          : "transparent",
                    })}
                  >
                    <ListItemButton>
                      <ListItemIcon>{item.icon}</ListItemIcon>
                      <ListItemText>
                        <Typography>{item.title}</Typography>
                      </ListItemText>
                    </ListItemButton>
                  </ListItem>
                </Link>
              ))}
            </List>
          )}
        </Box>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <Toolbar />
        <Outlet />
      </Box>
    </Box>
  );
}
