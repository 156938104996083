import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Chip,
  CircularProgress,
  Container,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import moment from "moment";
import FileDownload from "js-file-download";
import DownloadIcon from "@mui/icons-material/Download";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import {
  analyzeDocument,
  deleteDocument,
  downloadDocument,
  getDocument,
} from "../apis/documents";
import { humanFileSize } from "../helper/utils";
import { STATUS_COLOR_MAPPING } from "../helper/consts";
import { useSnackbar } from "../contexts/Snackbar";
import Loading from "../components/Loading";
import { useConfirm } from "../contexts/Confirm";
import { usePageTitle } from "../contexts/PageTitleContext";
import { getEnabledModels } from "../apis/models";
import ModelSelectionModal from "../components/documents/MdelSelectionModal";
import NoData from "../components/shared/NoData";
import DocumentOutput from "../components/documents/DocumentOutput";

let interval = null;

export default function Document() {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [document, setDocument] = useState();
  const [models, setModels] = useState([]);
  const confirm = useConfirm();
  const { showSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { setPageInfo } = usePageTitle();
  const [showModelSelection, setShowModelSelection] = useState(false);

  const fetchDocument = async () => {
    try {
      const { data } = await getDocument(id);
      setDocument(data);
    } catch (err) {
      console.error(err);
    }
  };

  const handleDownloadDocument = async (output, type) => {
    setLoading(true);

    try {
      const { data } = await downloadDocument(id, output?.modelId || "", type);

      FileDownload(
        data,
        !output
          ? document.fileName
          : type === "clean"
          ? output.cleanFileName
          : output.diffFileName
      );
    } catch (err) {
      console.error(err);
      showSnackbar({
        severity: "error",
        message: "Download Failed",
      });
    }

    setLoading(false);
  };

  const handleAnalyze = async (modelId) => {
    setShowModelSelection(false);

    if (document.status === "processing") {
      return showSnackbar({
        severity: "warning",
        message: "Please wait until the current request is completed",
      });
    }
    setDocument({
      ...document,
      status: "processing",
    });

    try {
      await analyzeDocument(id, modelId);
    } catch (err) {
      console.error(err);
      showSnackbar({
        severity: "error",
        message: "Something wrong, please try again later",
      });
    }
    await fetchDocument();
  };

  const handleDelete = async () => {
    if (
      !(await confirm({
        title: "Delete the document?",
        text: "The document will be deleted permanently.",
      }))
    ) {
      return;
    }

    try {
      await deleteDocument(id);
      showSnackbar({
        severity: "success",
        message: "The document has been deleted.",
      });
      navigate("/dashboard/documents");
    } catch (err) {
      console.error(err);
      showSnackbar({
        severity: "error",
        message: "Failed to delete the document",
      });
    }
  };

  useEffect(() => {
    fetchDocument();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (document?.status === "processing") {
      interval = setInterval(() => fetchDocument(), 3000);
    }
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [document?.status]);

  useEffect(() => {
    setPageInfo({ title: "AI Tools" });
    getEnabledModels().then((res) => setModels(res.data));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const modelName = useCallback(
    (modelId) => {
      return models.find((m) => m.id === modelId)?.name || "";
    },
    [models]
  );

  return (
    <div>
      {loading && <Loading />}
      {document && (
        <Container maxWidth="md" sx={{ marginLeft: 0, marginRight: "auto" }}>
          <Typography variant="h5" mb={4}>
            AI Tools
          </Typography>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            mb={4}
          >
            <Grid item>
              <Typography
                sx={{
                  maxWidth: 400,
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                }}
                color="primary"
              >
                {document.fileName}
              </Typography>
            </Grid>
            <Grid item>
              <Button
                variant="outlined"
                color="error"
                sx={{ mr: 2 }}
                onClick={handleDelete}
              >
                Delete
              </Button>
              <Button
                sx={{ mr: 2 }}
                variant="contained"
                color="orange"
                onClick={() => setShowModelSelection(true)}
              >
                Analyze
              </Button>
              <Button
                variant="contained"
                color="orange"
                onClick={() => handleDownloadDocument("", "original")}
              >
                <DownloadIcon />
              </Button>
            </Grid>
          </Grid>
          <Grid container spacing={3} alignItems="center" mb={4}>
            <Grid item xs={6} md={3}>
              <Chip
                label={document.status}
                color={STATUS_COLOR_MAPPING[document.status]}
                sx={{ textTransform: "uppercase" }}
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <Typography color="primary">Size</Typography>
              <Typography>{humanFileSize(document.fileSize)}</Typography>
            </Grid>
            <Grid item xs={6} md={3}>
              <Typography color="primary">Created At</Typography>
              <Typography>
                {moment(document.createdAt).format("YYYY-MM-DD hh:mm")}
              </Typography>
            </Grid>
            {document.status === "processing" && (
              <Grid item xs={6} md={3} display="flex" justifyContent="flex-end">
                <CircularProgress color="secondary" />
              </Grid>
            )}
          </Grid>
          <Divider />
          <Box mt={4} mb={4}>
            <Typography mb={4}>Outputs:</Typography>
            {document?.outputs?.length > 0 ? (
              document.outputs.map((output) => (
                <Accordion key={output.id}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>{modelName(output.modelId)}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <DocumentOutput
                      output={output}
                      handleDownloadDocument={handleDownloadDocument}
                    />
                  </AccordionDetails>
                </Accordion>
              ))
            ) : (
              <NoData />
            )}
          </Box>
        </Container>
      )}
      {showModelSelection && (
        <ModelSelectionModal
          models={models}
          onClose={() => setShowModelSelection(false)}
          onSelect={handleAnalyze}
        />
      )}
    </div>
  );
}
