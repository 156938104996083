import { useEffect, useState } from "react";
import {
  Card,
  CircularProgress,
  Grid,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

import useLoading from "../../hooks/useLoading";
import LoadableSection from "../../components/shared/LoadableSection";
import { getModels, setModel } from "../../apis/models";
import { useSnackbar } from "../../contexts/Snackbar";

export default function Models() {
  const [data, setData] = useState();
  const { loading, sendRequest } = useLoading();
  const [onAction, setOnAction] = useState();
  const { showSnackbar } = useSnackbar();

  const fetchModels = async () => {
    const { data } = await sendRequest(
      () => getModels(),
      "Failed to load models"
    );
    setData(data);
  };

  const handleEnable = async (model, enable) => {
    setOnAction(model.id);

    try {
      await setModel(model.id, enable);
      setData((v) =>
        v.map((item) =>
          item === model
            ? {
                ...model,
                enabled: enable,
              }
            : item
        )
      );
    } catch (err) {
      console.error(err);
      showSnackbar({ severity: "error", message: "Something went wrong" });
    }
    setOnAction(null);
  };

  useEffect(() => {
    fetchModels();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Grid container alignItems="center" justifyContent="space-between" mb={3}>
        <Typography variant="h6">Models</Typography>
      </Grid>

      <LoadableSection loading={loading}>
        {data?.length > 0 && (
          <>
            <TableContainer sx={{ mb: 3 }} component={Card}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ py: 2 }}>Name</TableCell>
                    <TableCell>Base Model</TableCell>
                    <TableCell align="center">Enabled</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.map((item) => (
                    <TableRow
                      key={item.id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell>
                        <Typography
                          fontSize={14}
                          overflow="hidden"
                          color="primary"
                          noWrap
                          textOverflow="ellipsis"
                        >
                          {item.name}
                        </Typography>
                      </TableCell>

                      <TableCell>
                        <Typography fontSize={14} color="secondary">
                          {item.model}
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        {item.id === onAction ? (
                          <CircularProgress size={20} color="secondary" />
                        ) : (
                          <Switch
                            color="secondary"
                            checked={item.enabled}
                            onChange={(_, v) => handleEnable(item, v)}
                          />
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        )}
      </LoadableSection>
    </div>
  );
}
