import { useEffect, useState } from "react";
import {
  Button,
  Card,
  Chip,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import moment from "moment";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import { useNavigate } from "react-router-dom";

import useLoading from "../hooks/useLoading";
import {
  createDocument,
  deleteDocument,
  getDocuments,
} from "../apis/documents";
import useQuery from "../hooks/useQuery";
import LoadableSection from "../components/shared/LoadableSection";
import UploadDocModal from "../components/documents/UploadDocModal";
import { useSnackbar } from "../contexts/Snackbar";
import { humanFileSize } from "../helper/utils";
import { useConfirm } from "../contexts/Confirm";
import Pagination from "../components/shared/Pagination";
import Link from "../components/shared/Link";
import { STATUS_COLOR_MAPPING } from "../helper/consts";

export default function Documents() {
  const [data, setData] = useState();
  const { loading, sendRequest } = useLoading();
  const q = useQuery();
  const [showUpload, setShowUpload] = useState(false);
  const { showSnackbar } = useSnackbar();
  const confirm = useConfirm();
  const navigate = useNavigate();

  const fetchDocuments = async () => {
    const { data } = await sendRequest(
      () =>
        getDocuments({
          page: q.get("page") || 1,
        }),
      "Failed to load documents"
    );
    setData(data);
  };

  const handleUpload = async (file) => {
    setShowUpload(false);
    const formData = new FormData();
    formData.append("file", file);

    try {
      const { data } = await createDocument(formData);
      showSnackbar({
        severity: "success",
        message: "Uploaded Successfully",
      });
      navigate(`${data.id}`);
    } catch (err) {
      showSnackbar({
        severity: "error",
        message:
          err?.response?.data?.message || "Failed to upload the document",
      });
    }
  };

  const handleDelete = async (item) => {
    if (
      !(await confirm({
        title: "Delete the document?",
        text: "The document will be deleted permanently.",
      }))
    ) {
      return;
    }

    try {
      await deleteDocument(item.id);
      showSnackbar({
        severity: "success",
        message: "A doc has been deleted.",
      });
      fetchDocuments();
    } catch (err) {
      console.error(err);
      showSnackbar({
        severity: "error",
        message: "Failed to delete the document",
      });
    }
  };

  useEffect(() => {
    fetchDocuments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Grid container alignItems="center" justifyContent="space-between" mb={3}>
        <Typography variant="h6">Documents</Typography>
        <Button
          startIcon={<AddIcon />}
          color="orange"
          variant="contained"
          onClick={() => setShowUpload(true)}
        >
          New Document
        </Button>
      </Grid>

      <LoadableSection loading={loading}>
        {data?.items?.length > 0 && (
          <>
            <TableContainer sx={{ mb: 3 }} component={Card}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ py: 2 }}>File Name</TableCell>
                    <TableCell>Size</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Created Date</TableCell>
                    <TableCell align="right"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.items.map((item) => (
                    <TableRow
                      key={item.id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell>
                        <Typography
                          fontSize={14}
                          overflow="hidden"
                          color="primary"
                          noWrap
                          textOverflow="ellipsis"
                          maxWidth={300}
                        >
                          {item.fileName}
                        </Typography>
                      </TableCell>

                      <TableCell>
                        <Typography fontWeight={500}>
                          {humanFileSize(item.fileSize)}
                        </Typography>
                      </TableCell>

                      <TableCell>
                        <Typography fontWeight={500}>
                          <Chip
                            label={item.status}
                            color={STATUS_COLOR_MAPPING[item.status]}
                            sx={{ textTransform: "uppercase" }}
                          />
                        </Typography>
                      </TableCell>

                      <TableCell>
                        {moment(item.createdAt).format("YYYY-MM-DD hh:mm")}
                      </TableCell>
                      <TableCell align="right">
                        <Link to={`${item.id}`}>
                          <Button
                            variant="outlined"
                            size="small"
                            color="primary"
                            sx={{ mr: 2 }}
                            endIcon={<ArrowOutwardIcon fontSize="small" />}
                          >
                            AI Tool
                          </Button>
                        </Link>
                        <IconButton
                          color="error"
                          onClick={() => handleDelete(item)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Pagination pagination={data.pagination} />
          </>
        )}
      </LoadableSection>

      {showUpload && (
        <UploadDocModal
          onClose={() => setShowUpload(false)}
          onSubmit={handleUpload}
        />
      )}
    </div>
  );
}
