import React from "react";
import ReactDOM from "react-dom/client";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

import "./index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { MaterialThemeProvider } from "./contexts/ThemeContext";
import { SnackbarProvider } from "./contexts/Snackbar";
import { AuthProvider } from "./contexts/AuthContext";
import { ConfirmProvider } from "./contexts/Confirm";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { PageTitleProvider } from "./contexts/PageTitleContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <MaterialThemeProvider>
      <SnackbarProvider>
        <ConfirmProvider>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <AuthProvider>
              <PageTitleProvider>
                <App />
              </PageTitleProvider>
            </AuthProvider>
          </LocalizationProvider>
        </ConfirmProvider>
      </SnackbarProvider>
    </MaterialThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
