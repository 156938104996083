import {
  Button,
  DialogActions,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { useState } from "react";

import {
  StyledDialog,
  StyledDialogContent,
  StyledDialogTitle,
} from "../shared/DialogElements";

export default function ModelSelectionModal({ models, onClose, onSelect }) {
  const [model, setModel] = useState("");

  return (
    <StyledDialog fullWidth open onClose={onClose} disableEscapeKeyDown>
      <StyledDialogTitle onClose={onClose}>Select a Model</StyledDialogTitle>
      <StyledDialogContent dividers>
        <RadioGroup value={model} onChange={(e) => setModel(e.target.value)}>
          {models.map((m) => (
            <FormControlLabel value={m.id} control={<Radio />} label={m.name} />
          ))}
        </RadioGroup>
      </StyledDialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <Button
          disabled={!model}
          color="orange"
          variant="contained"
          sx={{ mr: 1, my: 1, width: 120 }}
          onClick={() => onSelect(model)}
        >
          Select
        </Button>
      </DialogActions>
    </StyledDialog>
  );
}
