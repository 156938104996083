import NoData from "./NoData";
import SectionLoading from "./SectionLoading";

export default function LoadableSection({
  loading,
  children,
  showLoading = true,
  NoDataElement,
}) {
  return (
    <>
      {loading && (showLoading ? <SectionLoading /> : <></>)}
      {children || (loading ? <></> : NoDataElement || <NoData />)}
    </>
  );
}
