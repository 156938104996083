import { CssBaseline, ThemeProvider, createTheme } from "@mui/material";
import { createContext } from "react";

const ThemeContext = createContext({});

const theme = createTheme({
  palette: {
    primary: {
      main: "#7A86A1",
    },
    mode: "dark",
    info: {
      main: "#4EB7F2",
      contrastText: "#fff",
    },
    warning: {
      main: "#F9B035",
      contrastText: "#fff",
    },
    background: {
      default: "#1E2235",
      paper: "#24293E",
    },
    success: { main: "#49CDBD", contrastText: "#fff" },
    error: { main: "#F96767", contrastText: "#fff" },
    orange: {
      dark: "orange",
      contrastText: "white",
    },
    lightGrey: {
      main: "#7A86A1",
    },
  },
  components: {
    // Name of the component ⚛️ / style sheet
    MuiButton: {
      styleOverrides: {
        root: {
          "&.MuiButton-contained": {
            boxShadow: "none",
          },
          "&.MuiButton-containedOrange": {
            background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
            "&:hover": {
              boxShadow: "2px 2px 5px 2px rgba(255, 105, 135, .3)",
            },
            "&.Mui-disabled": {
              background: "rgba(255, 255, 255, 0.12)",
            },
          },
        },
      },
      // Name of the rule
    },
  },
});

export const MaterialThemeProvider = ({ children }) => {
  return (
    <ThemeContext.Provider value={{}}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </ThemeContext.Provider>
  );
};
